<template>
  <!--    编辑-->
  <el-dialog
    :title="formTitle"
    :visible.sync="messageBox"
    width="500px"
    class="addAlameBox"
    :close-on-click-modal="false"
  >
    <FormBox
      ref="formRef"
      :showFlag="showFlag"
      :formArr="formArr"
      :record="addForm"
      @saveForm="saveForm"
      @handleCancel="handleCancel"
      labelWidth="6vw"
      :fileListModify="fileListModify"
    ></FormBox>
  </el-dialog>
</template>

<script>
import FormBox from "@/components/lxw/Form/index.vue";
export default {
  components: { FormBox },
  props: {
    formTitle: {
      type: String,
      default: "新增",
    },
    messageBox: {
      type: Boolean,
      default: false,
    },
    showFlag: {
      type: Boolean,
      default: false,
    },
    record: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },

  data() {
    return {
      formArr: [
        {
          label: "病害名称",
          key: "name",
          type: "Input",
        },
        {
          label: "病害简介",
          key: "introduction",
          type: "Textarea",
        },
        {
          label: "防治措施",
          key: "measures",
          type: "Input",
        },
        {
          label: "投入品",
          key: "inputs",
          type: "Input",
        },
      ],
      addForm: {},
      rules: {
        // baseName: [
        //   {required: true, message: "基地名称不可为空", trigger: ["blur", "change"]},
        // ],
      },
      fileListModify: []
    };
  },
  watch: {
    record: {
      handler(newVal, oldVal) {
        this.addForm = newVal;
        if(newVal.diseaseImg){
          this.$getBlob("/fileops/show", {
            fileName: newVal.diseaseImg,
          }).then((res) => {
            let fileNames = res.config.params.fileName;
            let fileData = fileNames.split(".")[1];
            let blob = new Blob([res.data],{type: 'image/'+ fileData})
            let url = window.URL.createObjectURL(blob);
            this.fileListModify = [{name: newVal.diseaseImg, url: url, blob, fileData}];
          })
        }else{
          this.fileListModify = [];
        }

      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    // //console.log(this.record, "record");
  },
  methods: {
    getFileListAndForm(obj) {
      if (obj && obj.fileList) {
        const { fileList } = obj;
        let list = [];
        fileList.forEach((item) => {
          if (
            item.response &&
            item.response.data &&
            item.response.data.fileName
          ) {
            list.push(item.response.data.fileName);
          }
        });
        this.filesList = list;
      }
    },

    // 确认提交
    saveForm(param) {

      let url = "/insectPrevent/save";
      // if(this.formTitle == '新增'){
      //   delete this.addForm.id;
      // }

      this.$postJSON(url, param).then((res) => {
        if (res.data.state == "success") {
          if (this.formTitle == "新增") {
            this.$message.success("添加成功");
          } else {
            this.$message.success("修改成功");
          }
          this.$emit("success");
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    // 重置字段
    resetForm() {
      this.$refs["formRef"].resetForm();
    },

    handleCancel() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>

</style>